import Vue from "vue";
import {
  emergencyMapActions,
  emergencyMapState
} from "@/store/modules/emergency";
import { quickpayMapState } from "@/store/modules/quickpay";
import { logoPath } from "@/helpers/generalHelpers";
import { getSystemFilesURL } from "@/helpers";
export default Vue.extend({
  data() {
    return {
      modalDetails: { name: "", description: "", title: "", icon: "" },
      activeTabName: "inquiry",
      tempEmergency: false,
      logoPath: "",
      quickPayBackgroundImage: "",
      loading: false,
      country: ""
    };
  },
  async mounted() {
    try {
      const [logoPathImage, quickPayBackgroundImage] = await Promise.all([
        logoPath(),
        getSystemFilesURL("quickpay-bg")
      ]);
      if (logoPathImage) {
        this.logoPath = logoPathImage;
      }
      if (quickPayBackgroundImage) {
        this.quickPayBackgroundImage = quickPayBackgroundImage;
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }
  },
  methods: {
    ...emergencyMapActions(["checkEmergency"]),
    getLogoPath() {
      return logoPath();
    },
    handleClick() {
      //do nothing
    },
    showModal(details: {
      title: string;
      description: string;
      icon: string;
      modalName: string;
    }) {
      this.modalDetails.title = details.title;
      this.modalDetails.description = details.description;
      this.modalDetails.icon = details.icon;
      this.$modal.show(details.modalName);
    },
    doShutDown() {
      this.$router.push({
        path: `/emergency-maintenance`,
        query: {
          message:
            "Sorry, payment feature is undergoing emergency maintenance. Please try again later"
        }
      });
    },
    gotoPrevious() {
      this.activeTabName = "inquiry";
    },
    geoLocationError() {
      this.modalDetails.title = `Service Unavailable in Your Country (${this.country})`;
      this.modalDetails.description =
        "We’re sorry, but payments are only supported within the United States.  Please Call Billing at 888-786-0003, option #2";
      this.$modal.show("quickPayModal");
    },
    async userIsInUS(): Promise<boolean> {
      try {
        if (
          process.env.VUE_APP_ENV !== "production" &&
          this.$route.query.mode != "test"
        ) {
          return true;
        }
        this.loading = true;
        const apiKey = process.env.VUE_APP_GOOGLE_API_PLACES_KEY;

        if (!apiKey) {
          throw new Error("Missing API key");
        }

        // Get location using Google's Geolocation API
        const response = await fetch(
          `https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`,
          {
            method: "POST"
          }
        );

        const data = await response.json();
        if (!data.location) {
          this.geoLocationError();
        }

        const { lat = "", lng = "" } = data.location;

        if (!lat || !lng) {
          this.geoLocationError();
        }

        // Reverse Geocode to get country
        const geoResponse = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
        );
        const geoData = await geoResponse.json();

        if (!geoData.results || geoData.results.length === 0) {
          this.geoLocationError();
        }

        // Extract country from address components

        for (const result of geoData.results) {
          const countryComponent = result.address_components.find((comp: any) =>
            comp.types.includes("country")
          );
          if (countryComponent) {
            this.country = countryComponent.long_name;
            break;
          }
        }

        if (!this.country) {
          this.geoLocationError();
        }

        return this.country === "United States";
      } catch (error) {
        this.geoLocationError();
        this.$bugSnagClient.notify(error);
        return false;
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    ...quickpayMapState(["makingApiRequest", "policyInfo"]),
    ...emergencyMapState(["emergencyData"]),
    initWithBillPayData(): boolean {
      return !!(
        this.$route.query.prefix &&
        this.$route.query.policyNumber &&
        this.$route.query.companyNumber
      );
    }
  },
  watch: {
    "emergencyData.isInEmergencyMaintenanceMode": {
      handler(isInEmergencyMaintenanceMode: boolean) {
        if (isInEmergencyMaintenanceMode) {
          this.doShutDown();
        }
      },
      immediate: false
    },
    "emergencyData.isInFiservScheduledMaintenanceMode": {
      handler(isInFiservScheduledMaintenanceMode: boolean) {
        if (isInFiservScheduledMaintenanceMode) {
          this.$router.push({
            path: `/emergency-maintenance`,
            query: {
              message: this.emergencyData?.fiservMaintenanceModeMessage
            }
          });
        }
      },
      immediate: false
    }
  }
});
