
import PolicyInfo from "./PolicyInfo.vue";
import PaymentInfo from "./PaymentInfo.vue";
import Footer from "./quickPayFooter.vue";
import { Tabs, TabPane } from "element-ui";
import { authMapActions } from "@/store/modules/auth";
import VueWithMixins from "@/helpers/mixins";
import QuickPayMixin from "./QuickPayMixin";

export default VueWithMixins(QuickPayMixin).extend({
  components: {
    PolicyInfo,
    PaymentInfo,
    "el-tabs": Tabs,
    "el-tab-pane": TabPane,
    Footer
  },
  data() {
    return {
      plugin: {} as HTMLScriptElement,
      date: "",
      modalName: "quickPayModal",
      isInUS: false
    };
  },
  async mounted() {
    this.checkEmergency();

    this.$nextTick(async () => {
      this.isInUS = await this.userIsInUS();
      if (!this.isInUS) {
        this.geoLocationError();
      }
    });

    //temporary fix
    const quickpayMain = document.querySelector(".quickpay--main") as any;
    if (quickpayMain.parentNode)
      quickpayMain.parentNode.classList.remove("wrap");

    if (this.tempEmergency) {
      this.$router.push({
        path: `/403`,
        query: {
          message:
            "Payment system down for emergency maintenance. Please try again later"
        }
      });
    }
  },
  methods: {
    ...authMapActions(["logout"]),
    handleShowModal($event: any) {
      this.showModal({ ...$event, modalName: "quickPayModal" });
    },

    showPaidInFullModal() {
      this.modalDetails.title = "Policy paid in full";
      this.modalDetails.description =
        "This policy is paid in full. If you have further questions,please call our billing department at 888-786-0003 or email Billing at AtlasBilling@AtlasGeneral.com";
      this.$modal.show("quickPayModal");
    },
    showMinimumAmountDueModal(date: string) {
      this.modalDetails.title = "Payment past due";
      this.modalDetails.description = `Payment past due – Policy will lapse unless the minimum due amount is received by ${date}`;
      this.$modal.show("quickPayModal");
    },
    gotoNext(policyInfo: any) {
      this.activeTabName = "pay";

      this.$nextTick(() => {
        const paymentTab = this.$refs.paymentTab as any;
        const fieldRef = paymentTab?.field_currentAmountDue;
        if (fieldRef && fieldRef.$el) {
          const fieldRefInput = fieldRef.$el.querySelector("input");
          fieldRefInput.focus();
        }
      });
      const status = policyInfo.Summary.PolicyStatus;
      const date = policyInfo.Summary.ComputedCancellationDt;
      if (status === "P") {
        this.showPaidInFullModal();
      } else if (status == "C") {
        this.showMinimumAmountDueModal(date);
      }
    },
    gotoPrevious() {
      this.activeTabName = "inquiry";
    }
  },
  computed: {
    initWithBillPayData(): boolean {
      return !!(
        this.$route.query.prefix &&
        this.$route.query.policyNumber &&
        this.$route.query.companyNumber
      );
    }
  }
});
